<template>
    <demographics @proceed='next'>
        <fieldset :style='{ marginBottom: "1rem" }'>
            <legend>האם {{ $parse('אתה', 'את') }} עדיין חולה בקורונה?</legend>
            <label multi-choice>
                <input type='radio' :value='true' v-model='study.hasCorona' />
                כן
            </label>
            <label multi-choice>
                <input type='radio' :value='false' v-model='study.hasCorona' />
                לא
            </label>
            <div class='error' v-show='study.dem.button === "invalid"'>
                נא לבחור אחת מהאפשרויות
            </div>
        </fieldset>
    </demographics>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import { updateSubject, goToQuestionnaire } from '@/Study/helpers';
import Demographics from '@/Study/Pages/Demographics.vue';

export default defineComponent({
    components: { Demographics },
    computed: {
        study(){
            return this.$store.state.study!;
        },
        user(){
            return this.$store.state.user;
        }
    },
    watch: {
        'study.hasCorona'(){
            this.study.dem.button = 'continue';
        }
    },
    methods: {
        async next(){
            const { dem, hasCorona } = this.study;
            const { sex } = this.$store.state.user;

            if(typeof hasCorona !== 'boolean')
                return dem.button = 'invalid';

            if(dem.button !== 'continue')
                return;

            dem.button = 'loading';

            try{
                const updates = { sex: sex ?? null, age: dem.age ?? null, hasCorona };
                updateSubject(updates);
                await goToQuestionnaire();
                dem.button = 'continue';
            }catch{
                dem.button = 'error';
            }
        }
    }
});
</script>