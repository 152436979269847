
import { defineComponent } from '@vue/runtime-core';
import { updateSubject, goToQuestionnaire } from '@/Study/helpers';
import Demographics from '@/Study/Pages/Demographics.vue';

export default defineComponent({
    components: { Demographics },
    computed: {
        study(){
            return this.$store.state.study!;
        },
        user(){
            return this.$store.state.user;
        }
    },
    watch: {
        'study.hasCorona'(){
            this.study.dem.button = 'continue';
        }
    },
    methods: {
        async next(){
            const { dem, hasCorona } = this.study;
            const { sex } = this.$store.state.user;

            if(typeof hasCorona !== 'boolean')
                return dem.button = 'invalid';

            if(dem.button !== 'continue')
                return;

            dem.button = 'loading';

            try{
                const updates = { sex: sex ?? null, age: dem.age ?? null, hasCorona };
                updateSubject(updates);
                await goToQuestionnaire();
                dem.button = 'continue';
            }catch{
                dem.button = 'error';
            }
        }
    }
});
